// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-quiz-step-2-page-js": () => import("../src/templates/quiz/step2Page.js" /* webpackChunkName: "component---src-templates-quiz-step-2-page-js" */),
  "component---src-templates-quiz-step-3-page-js": () => import("../src/templates/quiz/step3Page.js" /* webpackChunkName: "component---src-templates-quiz-step-3-page-js" */),
  "component---src-templates-quiz-step-4-page-js": () => import("../src/templates/quiz/step4Page.js" /* webpackChunkName: "component---src-templates-quiz-step-4-page-js" */),
  "component---src-templates-quiz-step-5-page-js": () => import("../src/templates/quiz/step5Page.js" /* webpackChunkName: "component---src-templates-quiz-step-5-page-js" */),
  "component---src-templates-quiz-step-6-page-js": () => import("../src/templates/quiz/step6Page.js" /* webpackChunkName: "component---src-templates-quiz-step-6-page-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-successpage-js": () => import("../src/pages/successpage.js" /* webpackChunkName: "component---src-pages-successpage-js" */)
}

